import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './css/ProductTable.css';
import { Util } from 'src/Util';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#424242',
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#EEEEEE'
  },
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));

export default function ProductTable(invoiceData: any) {
  return (
    <TableContainer sx={{ maxHeight: 400, minHeight: 400 }} component={Paper}>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell sx={{ maxWidth: 80 }}>No.</StyledTableCell>
            <StyledTableCell align="left">Item Description</StyledTableCell>
            <StyledTableCell align="right">Amount&nbsp;</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invoiceData?.invoiceData?.invoiceItems.map((product, index) => (
            <StyledTableRow key={product?._id}>
              <StyledTableCell component="th" scope="row">
                <strong>{index + 1}.</strong>
              </StyledTableCell>
              <StyledTableCell align="left">
                <div className="row-content">
                  <p>
                    <strong>
                      {product?.manualProductName != null
                        ? product?.manualProductName
                        : '[ SAMPLE PRODUCT ]'}
                    </strong>
                  </p>
                  <p>
                    <strong>
                      {Util.formatNumber(product?.price)} x {product?.quantity} {(product?.productType === 'Kg' || product?.productType === 'Litre' || product?.productType === 'Mitre') ? product?.productType : ''}
                    </strong>
                  </p>
                </div>
              </StyledTableCell>
              <StyledTableCell align="right">
                <strong>{Util.formatNumber(product?.amount)} {invoiceData.invoiceData?.originalCurrency?.currencyName}</strong>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
