// src/ErrorPage.js
import React from 'react';
import { Link } from 'react-router-dom';
import './css/ErrorPage.css';

function ErrorPage({ error }) {
  return (
    <div className="container">
      <h1 className="header">404</h1>
      <p className="message">{error}</p>
    </div>
  );
}

export default ErrorPage;
