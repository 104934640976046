import axios from 'axios';
import { AppResponse } from '../models/Response';
import { Util } from '../Util';

export class InvoiceService {
  //get invoice data for public route
  public static async getInvoiceDisplayData(
    invoiceId: any
  ): Promise<AppResponse<any>> {
    const url = Util.apiPublicUrl(`invoice-link-data/${invoiceId}`);

    const res = await axios.get<void, AppResponse<any>>(url);

    return res;
  }

  public static async getTotalOutstandingAmountByCustomerId(
    shopId: string,
    customerId: string
  ): Promise<AppResponse<any>> {
    const url = Util.apiPublicUrl(`getTotalOutstandingAmountByCustomerId`);

    const res = await axios.post<void, AppResponse<any>>(url, {
      shopId,
      customerId
    });

    return res;
  }

  //get invoice data for public route
  public static async sendInvoiceData(
    invoiceId: string,
    phoneNumber: string
  ): Promise<AppResponse<any>> {
    const url = Util.apiPublicUrl(`save-invoice-by-phone-number`);

    const res = await axios.post<void, AppResponse<any>>(url, {
      invoiceId,
      phoneNumber
    });

    return res;
  }
}
