import React, { useEffect, useRef, useState } from 'react';
import './Invoice.css';
import Header from './Header';
import ProductTable from './ProductTable';
import { InvoiceService } from 'src/services/InvoiceService';
import BottomSection from './BottomSection';
import FooterSection from './FooterSection';
import ErrorPage from './errorPage';
import './css/SkeletonLoader.css';

function PublicInvoiceView() {
  const [isLoading, setIsLoading] = useState(true);
  const [errorOccurred, setErrorOccurred] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const [invoiceData, setInvoiceData] = useState<any>(null);
  const invoiceNumberRef = useRef(null);

  const getInvoiceData = async (invoiceNumber: string) => {
    try {
      const response = await InvoiceService.getInvoiceDisplayData(
        invoiceNumber
      );

      if (response.success) {
        setInvoiceData(response.data);
        setIsLoading(false);
      } else {
        setError(response.error);
        setErrorOccurred(true);
      }
    } catch (error) {
      setIsLoading(false);
      setError('Connection Error!');
      setErrorOccurred(true);
    }
  };

  useEffect(() => {
    const url = window.location.href;
    const invoiceNumber = url.split('/').pop();
    invoiceNumberRef.current = invoiceNumber;

    getInvoiceData(invoiceNumberRef.current);
  }, []);

  return (
    <>
      {!errorOccurred ? (
        isLoading ? 
        <div className="invoice-container">
          <div className="skeleton-wrapper">
            <div className="skeleton-header"></div>
            <div className="skeleton-line"></div>
            <div className="skeleton-line"></div>
            <div className="skeleton-header"></div>
            <div className="skeleton-line"></div>
            <div className="skeleton-line"></div>
            <div className="skeleton-line"></div>
            <div className="skeleton-line"></div>
            <div className="skeleton-header"></div>
            <div className="skeleton-line"></div>
            <div className="skeleton-line"></div>
            <div className="skeleton-line"></div>
          </div>
        </div> :
        <div className="invoice-container">
          <Header invoiceData={invoiceData} />
          <ProductTable invoiceData={invoiceData} />
          <BottomSection invoiceData={invoiceData} />
          <FooterSection invoiceData={invoiceData} />
        </div>
      ) : (
        <div>
          <ErrorPage error={error} />
        </div>
      )}
      <div className="bottom-space"></div>
    </>
  );
}

export default PublicInvoiceView;
