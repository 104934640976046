import { useEffect } from 'react';
import './Invoice.css';
import { useNavigate } from 'react-router-dom';

function PublicInvoiceViewOld() {
  const navigate = useNavigate();

  useEffect(() => {
    const url = window.location.href;
    const invoiceNumber = url.split('/').pop();

    navigate('/invoice/'+invoiceNumber);
  }, []);

  return (
    <>
      <p>Redirecting...</p>
    </>
  );
}

export default PublicInvoiceViewOld;
