import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages

// Status

const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/content/pages/Status/Maintenance'))
);

import PublicInvoiceView from './content/pages/CustomerInvoiceView/PublicInvoiceView';
import SuspenseLoader from './components/SuspenseLoader';
import PublicInvoiceViewOld from './content/pages/CustomerInvoiceView/PublicInvoiceViewOld';

const routes: RouteObject[] = [
  {
    path: '',
    children: [
      {
        path: 'status',
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          }
        ]
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },
  {
    path: 'public-invoice-view',
    element: <PublicInvoiceViewOld />,
    children: [
      {
        path: '',
        element: <PublicInvoiceViewOld />
      },
      {
        path: '*',
        element: <PublicInvoiceViewOld />
      }
    ]
  },
  {
    path: 'invoice',
    element: <PublicInvoiceView />,
    children: [
      {
        path: '',
        element: <PublicInvoiceView />
      },
      {
        path: '*',
        element: <PublicInvoiceView />
      }
    ]
  }
];

export default routes;
