import { useState, useEffect } from 'react';
import './css/BottomSection.css';
import { InvoiceService } from 'src/services/InvoiceService';
import Swal from 'sweetalert2';
import { environment } from 'src/environment/environment';
import { PaymentOptions } from 'src/enums/PaymentOptions';
import { Util } from 'src/Util';
import moment from 'moment';

const BottomSection = (invoiceData: any) => {
  const [invoiceCurrency, setInvoiceCurrency] = useState('');
  const [
    isProductOrCategoryDiscountsAvailable,
    setIsProductOrCategoryDiscountsAvailable
  ] = useState(false);
  const [updatedInvoiceValue, setUpdatedInvoiceValue] = useState<number>(0);
  let [invoiceValueAfterCharge, setInvoiceValueAfterCharge] =
    useState<number>(0);
  const [discountAmount, setDiscountAmount] = useState<number>(0);
  const [serviceCharge, setServiceCharge] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(true);
  const [errorOccurred, setErrorOccurred] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const [totalOustandingAmount, setTotalOustandingAmount] = useState<any>(null);
  const [taxCharge, setTaxCharge] = useState<number>(0);
  const [deliveryCharge, setDeliveryCharge] = useState<number>(0);

  useEffect(() => {
    let totalValue = invoiceData.invoiceData?.subTotal;

    if (invoiceData.invoiceData?.discount > 0) {
      if (invoiceData.invoiceData?.discountType == 'PAYRATE') {
        setDiscountAmount(invoiceData.invoiceData?.discount);
        totalValue = totalValue - invoiceData.invoiceData?.discount;
      } else {
        setDiscountAmount(
          invoiceData.invoiceData?.subTotal *
            (invoiceData.invoiceData?.discount / 100)
        );
        totalValue =
          totalValue -
          invoiceData.invoiceData?.subTotal *
            (invoiceData.invoiceData?.discount / 100);
      }
    }

    setInvoiceCurrency(invoiceData.invoiceData?.originalCurrency?.currencyName);
    setUpdatedInvoiceValue(totalValue);

    if (invoiceData.invoiceData?.invoiceItems != null) {
      invoiceData.invoiceData?.invoiceItems.forEach((item: any) => {
        if (
          item.productOrCategoryDiscount != null &&
          item.productOrCategoryDiscount > 0
        ) {
          if (item.productOrCategoryDiscountType == 'PAYRATE') {
            totalValue =
              totalValue - item.productOrCategoryDiscount * item.quantity;
          } else {
            totalValue =
              totalValue - (item.amount * item.productOrCategoryDiscount) / 100;
          }

          setUpdatedInvoiceValue(totalValue);
          setIsProductOrCategoryDiscountsAvailable(true);
        }
      });
    }

    setInvoiceValueAfterCharge(totalValue);
    let finalInvoiceValue = totalValue;

    if (
      invoiceData.invoiceData?.serviceCharge != null &&
      invoiceData.invoiceData?.serviceCharge != undefined &&
      invoiceData.invoiceData?.serviceCharge != 0
    ) {
      if (invoiceData.invoiceData?.serviceChargeType == 'PAYRATE') {
        setServiceCharge(invoiceData.invoiceData?.serviceCharge);
        finalInvoiceValue =
          finalInvoiceValue + invoiceData.invoiceData?.serviceCharge;
      } else {
        setServiceCharge(
          (totalValue * invoiceData.invoiceData?.serviceCharge) / 100
        );
        finalInvoiceValue =
          finalInvoiceValue +
          (totalValue * invoiceData.invoiceData?.serviceCharge) / 100;
      }
    }

    if (
      invoiceData.invoiceData?.taxCharge != null &&
      invoiceData.invoiceData?.taxCharge != undefined &&
      invoiceData.invoiceData?.taxCharge != 0
    ) {
      if (invoiceData.invoiceData?.taxChargeType == 'PAYRATE') {
        setTaxCharge(invoiceData.invoiceData?.taxCharge);
        finalInvoiceValue =
          finalInvoiceValue + invoiceData.invoiceData?.taxCharge;
      } else {
        setTaxCharge((totalValue * invoiceData.invoiceData?.taxCharge) / 100);
        finalInvoiceValue =
          finalInvoiceValue +
          (totalValue * invoiceData.invoiceData?.taxCharge) / 100;
      }
    }

    if (
      invoiceData.invoiceData?.deliveryCharge != null &&
      invoiceData.invoiceData?.deliveryCharge != undefined &&
      invoiceData.invoiceData?.deliveryCharge != 0
    ) {
      setDeliveryCharge(invoiceData.invoiceData?.deliveryCharge);
      finalInvoiceValue =
        finalInvoiceValue + invoiceData.invoiceData?.deliveryCharge;
    }
    setInvoiceValueAfterCharge(finalInvoiceValue);

    getTotalOutstandingAmountByCustomerId(
      invoiceData.invoiceData?.shopId?._id,
      invoiceData.invoiceData?.customerId
    );
  }, [invoiceData]);

  const getTotalOutstandingAmountByCustomerId = async (
    shopId: string,
    customerId: string
  ) => {
    const response = await InvoiceService.getTotalOutstandingAmountByCustomerId(
      shopId,
      customerId
    );
    if (response.success) {
      setTotalOustandingAmount(response.data);
      setIsLoading(false);
    } else {
      setError(response.error);
      setErrorOccurred(true);
    }
  };

  return (
    <>
      <div className="bottom-section-container">
        <div className="inv-sec">
          <div className="inv-sec1">Sub Total:</div>
          <div className="inv-sec2">
            {Util.formatNumber(invoiceData.invoiceData?.subTotal)}{' '}
            {invoiceCurrency}
          </div>
        </div>

        {invoiceData.invoiceData?.discount != null &&
          invoiceData.invoiceData?.discount != undefined &&
          invoiceData.invoiceData?.discount != 0 && (
            <>
              <div className="inv-sec">
                <div className="inv-sec1">
                  Discount{' '}
                  {invoiceData.invoiceData?.discountType != 'PAYRATE'
                    ? invoiceData.invoiceData?.discount + '%'
                    : ''}
                  :
                </div>
                <div className="inv-sec2">
                  -{Util.formatNumber(discountAmount)} {invoiceCurrency}
                </div>
              </div>
            </>
          )}

        {isProductOrCategoryDiscountsAvailable && (
          <>
            <div className="inv-sec">
              <div className="inv-sec1">
                <b>Line Discounts</b>
              </div>
              <div className="inv-sec2"></div>
            </div>

            {invoiceData?.invoiceData?.invoiceItems.map(
              (item: any, index: any) => (
                <div key={index}>
                  {item.productOrCategoryDiscount != null &&
                    item.productOrCategoryDiscount! > 0 && (
                      <>
                        {
                          item.productOrCategoryDiscountType == 'PAYRATE' ? (
                            <>
                              <div className="inv-sec">
                                <div className="inv-sec1">
                                  Item #{index + 1}
                                </div>
                              </div>
                              <div className="inv-sec">
                                <div className="inv-sec3">
                                  {Util.formatNumber(item.productOrCategoryDiscount!)} {invoiceCurrency} x {item.quantity}
                                </div>
                                <div className="inv-sec2">
                                  -{Util.formatNumber(item.productOrCategoryDiscount * item.quantity)}{' '}{invoiceCurrency}
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="inv-sec">
                                <div className="inv-sec1">
                                  Item #{index + 1} [
                                  {item.productOrCategoryDiscount + '%'}]
                                </div>
                              </div>
                              <div className="inv-sec">
                                <div className="inv-sec3">
                                  {Util.formatNumber(Math.round(item.price * (item.productOrCategoryDiscount! / 100)))} {invoiceCurrency} x {item.quantity}
                                </div>
                                <div className="inv-sec2">
                                  -
                                  {Util.formatNumber(
                                    Math.round((item.amount *
                                      item.productOrCategoryDiscount!) /
                                      100)
                                  )}{' '}
                                  {invoiceCurrency}
                                </div>
                              </div>
                            </>
                          )
                        }
                      </>
                    )}
                </div>
              )
            )}
          </>
        )}

        {(discountAmount || isProductOrCategoryDiscountsAvailable) && (
          <>
            <div className="seperate-line">
              <div className="sl"></div>
            </div>

            <div className="inv-sec">
              <div className="inv-sec1">Sub Total:</div>
              <div className="inv-sec2">
                {Util.formatNumber(updatedInvoiceValue)} {invoiceCurrency}
              </div>
            </div>
          </>
        )}

        {invoiceData.invoiceData?.serviceCharge != null &&
          invoiceData.invoiceData?.serviceCharge != undefined &&
          invoiceData.invoiceData?.serviceCharge != 0 && (
            <>
              <div className="inv-sec">
                <div className="inv-sec1">
                  Service Charge:{' '}
                  {invoiceData.invoiceData?.serviceChargeType == 'PERCENTAGE' &&
                    `[${invoiceData.invoiceData?.serviceCharge}%]`}
                </div>
                <div className="inv-sec2">
                  +{Util.formatNumber(serviceCharge)} {invoiceCurrency}
                </div>
              </div>

              <div className="seperate-line">
                <div className="sl"></div>
              </div>
            </>
          )}

        {invoiceData.invoiceData?.taxCharge != null &&
          invoiceData.invoiceData?.taxCharge != undefined &&
          invoiceData.invoiceData?.taxCharge != 0 && (
            <>
              <div className="inv-sec">
                <div className="inv-sec1">
                  Tax Charge:{' '}
                  {invoiceData.invoiceData?.taxChargeType == 'PERCENTAGE' &&
                    `[${invoiceData.invoiceData?.taxCharge}%]`}
                </div>
                <div className="inv-sec2">
                  +{Util.formatNumber(taxCharge)} {invoiceCurrency}
                </div>
              </div>

              <div className="seperate-line">
                <div className="sl"></div>
              </div>
            </>
          )}

        {invoiceData.invoiceData?.deliveryCharge != null &&
          invoiceData.invoiceData?.deliveryCharge != undefined &&
          invoiceData.invoiceData?.deliveryCharge != 0 && (
            <>
              <div className="inv-sec">
                <div className="inv-sec1">Delivery Charge:</div>
                <div className="inv-sec2">
                  +{Util.formatNumber(deliveryCharge)} {invoiceCurrency}
                </div>
              </div>

              <div className="seperate-line">
                <div className="sl"></div>
              </div>
            </>
          )}

        <div className="inv-sec">
          <div className="inv-sec1">
            <b>Total:</b>
          </div>
          <div className="inv-sec2">
            <b>
              {Util.formatNumber(invoiceValueAfterCharge)} {invoiceCurrency}
            </b>
          </div>
        </div>

        <div className="seperate-line">
          <div className="sl"></div>
        </div>

        {invoiceData.invoiceData?.paymentOption != null &&
          invoiceData.invoiceData?.paymentOption == PaymentOptions.CASH && (
            <>
              <div className="inv-sec">
                <div className="inv-sec1">
                  <b>Cash Invoice</b>
                </div>
                <div className="inv-sec2"></div>
              </div>

              <div className="inv-sec">
                <div className="inv-sec1">Paid Amount</div>
                <div className="inv-sec2">
                  {Util.formatNumber(invoiceData.invoiceData?.paidAmount)}{' '}
                  {invoiceCurrency}
                </div>
              </div>

              <div className="inv-sec">
                <div className="inv-sec1">
                  <b>Balance</b>
                </div>
                <div className="inv-sec2">
                  <b>
                    {Util.formatNumber(invoiceData.invoiceData?.balance)}{' '}
                    {invoiceCurrency}
                  </b>
                </div>
              </div>
            </>
          )}

        {invoiceData.invoiceData?.paymentOption != null &&
          invoiceData.invoiceData?.paymentOption == PaymentOptions.CREDIT && (
            <>
              <div className="inv-sec">
                <div className="inv-sec1">
                  <b>Credit Invoice</b>
                </div>
                <div className="inv-sec2"></div>
              </div>

              <div className="inv-sec">
                <div className="inv-sec1">Paid Amount</div>
                <div className="inv-sec2">
                  {Util.formatNumber(invoiceData.invoiceData?.paidAmount)}{' '}
                  {invoiceCurrency}
                </div>
              </div>

              <div className="inv-sec">
                <div className="inv-sec1">Outstanding</div>
                <div className="inv-sec2">
                  <b>
                    {Util.formatNumber(
                      invoiceData.invoiceData?.outstandingAmount
                    )}{' '}
                    {invoiceCurrency}
                  </b>
                </div>
              </div>

              {totalOustandingAmount != null &&
                totalOustandingAmount != undefined &&
                totalOustandingAmount.summary && (
                  <>
                    <div className="summary-sec">Outstanding Bill Summary</div>

                    {totalOustandingAmount.summary.map((summary, index) => {
                      const formattedDate = moment(summary.createdAt).format(
                        'D MMM YYYY'
                      );
                      return (
                        <div className="summary-sec-row" key={index}>
                          <div className="sub-sec1">{formattedDate}</div>
                          <div className="sub-sec2">
                            {summary.invoiceNumber}
                          </div>
                          <div className="sub-sec3">
                            {Util.formatNumber(summary.outstandingAmount)}{' '}
                            {invoiceCurrency}
                          </div>
                        </div>
                      );
                    })}

                    <div className="inv-sec">
                      <div className="inv-sec1">Total Outstanding</div>
                      <div className="inv-sec2">
                        <b>
                          {Util.formatNumber(
                            totalOustandingAmount.totalOutstandingAmount
                          )}{' '}
                          {invoiceCurrency}
                        </b>
                      </div>
                    </div>
                  </>
                )}
            </>
          )}

        {invoiceData.invoiceData?.paymentOption != null &&
          invoiceData.invoiceData?.paymentOption == PaymentOptions.CHEQUE && (
            <>
              <div className="inv-sec">
                <div className="inv-sec1">
                  <b>Cheque Invoice</b>
                </div>
                <div className="inv-sec2"></div>
              </div>

              <div className="inv-sec">
                <div className="inv-sec1">Paid Amount</div>
                <div className="inv-sec2">
                  {Util.formatNumber(invoiceData.invoiceData?.paidAmount)}{' '}
                  {invoiceCurrency}
                </div>
              </div>

              <div className="inv-sec">
                <div className="inv-sec1">Check Number</div>
                <div className="inv-sec2">
                  <b>{invoiceData.invoiceData?.chequeNo}</b>
                </div>
              </div>
            </>
          )}

        {invoiceData.invoiceData?.paymentOption != null &&
          invoiceData.invoiceData?.paymentOption ==
            PaymentOptions.BANK_TRANSFER && (
            <>
              <div className="inv-sec">
                <div className="inv-sec1">
                  <b>Bank Transfer Invoice</b>
                </div>
                <div className="inv-sec2"></div>
              </div>

              <div className="inv-sec">
                <div className="inv-sec1">Paid Amount</div>
                <div className="inv-sec2">
                  {Util.formatNumber(invoiceData.invoiceData?.paidAmount)}{' '}
                  {invoiceCurrency}
                </div>
              </div>

              <div className="inv-sec">
                <div className="inv-sec1">
                  <b>{invoiceData.invoiceData?.bankName}</b>
                </div>
              </div>
            </>
          )}

        {invoiceData.invoiceData?.paymentOption != null &&
          invoiceData.invoiceData?.paymentOption ==
            PaymentOptions.CARD_PAYMENT && (
            <>
              <div className="inv-sec">
                <div className="inv-sec1">
                  <b>Card Payment Invoice</b>
                </div>
                <div className="inv-sec2"></div>
              </div>

              <div className="inv-sec">
                <div className="inv-sec1">Paid Amount</div>
                <div className="inv-sec2">
                  {Util.formatNumber(invoiceData.invoiceData?.paidAmount)}{' '}
                  {invoiceCurrency}
                </div>
              </div>
            </>
          )}
      </div>
    </>
  );
};

export default BottomSection;
