import appLogo from '../../../assets/applogo.png';
import './css/FooterSection.css';
import { environment } from 'src/environment/environment';
import pdfDownload from '../../../assets/download-pdf.png';

const FooterSection = (invoiceData: any) => {
  const openPDF = () => {
    window.open(
      `${environment.pdf_url}/api/public/pdf/${invoiceData.invoiceData?._id}`,
      '_blank'
    );
  };

  return (
    <>
      {
        invoiceData.invoiceData?.shortNotes != null && invoiceData.invoiceData?.shortNotes != undefined && invoiceData.invoiceData?.shortNotes != "" &&
        <>
          <div className="border-line-1"></div>
        
          <div className='short-notes'>
            {invoiceData.invoiceData?.shortNotes}
          </div>
        </>
      }
      
      <div className="border-line"></div>
      
      <div className="footer-container">
        <div className='footer-main-s1'>
          <div className="footer-s2">
            <div className="budget-app-logo">
              <a href="https://fanbudget.com/" target="_blank" rel="noopener noreferrer">
                <img src={appLogo} alt="FanBudget Logo" />
              </a>
            </div>
          </div>

          <a href="https://fanbudget.com/" target="_blank" rel="noopener noreferrer" className='link-logo'>
            <div className="footer-s1">
              &nbsp; Software By FanBudget
            </div>
          </a>
        </div>
        <div className="footer-main-s2">
          <div className="download-pdf-div">
            <img src={pdfDownload} alt="PDF Download" className="pdf-download" onClick={openPDF} />
          </div>
        </div>
      </div>
    </>
  );
};

export default FooterSection;
