import './css/Header.css';
import moment from 'moment';
import defaultShop from '../../../assets/default-shop.png';
import { environment } from 'src/environment/environment';
import { Util } from 'src/Util';

const Header = (invoiceData: any) => {
  console.log("invoiceData", invoiceData)
  const timestamp = invoiceData.invoiceData?.createdAt;
  const dateTime = moment(timestamp);

  return (
    <div className="header-wrapper">
      <div className="header-container">
        <div className="main-title">
          <div className="logo">
            <img
              src={
                invoiceData.invoiceData?.shopId.profileImageId == undefined
                  ? defaultShop
                  : environment.api_url + "/api/public/file/" + invoiceData.invoiceData?.shopId.profileImageId._id + "/" + invoiceData.invoiceData?.shopId.profileImageId.originalName
              }
              alt="Logo"
              className="logo-image"
            />
          </div>

          <div className="store-info">
            <h1 className="store-name">
              {invoiceData.invoiceData?.shopId.shopName}
            </h1>
            <p className="store-address">
              {invoiceData.invoiceData?.shopId.shopAddress}
            </p>
            <p className="store-phone">
              {Util.formatPhoneNumber(invoiceData.invoiceData?.shopId.phoneNumber)}
            </p>
          </div>
        </div>
      </div>

      <div className="invoice-top-bar">
        <div className="invoice-bar"></div>

        <div className="invoice-text">INVOICE</div>
        <div className="invoice-bar02"></div>
      </div>

      <div className="invoice-primary-details">
        <div className="invoice-primary-details-01">
          <p>
            <strong>{invoiceData.invoiceData?.customerName}</strong>
          </p>
          <p>
            <strong>{Util.formatPhoneNumber(invoiceData.invoiceData?.customerPhoneNumber)}</strong>
          </p>
          {
            invoiceData.invoiceData?.customerAddress != null && invoiceData.invoiceData?.customerAddress != undefined
            &&
            <p>
              <strong>{invoiceData.invoiceData?.customerAddress}</strong>
            </p>
          }
          <p>
            <strong>{invoiceData.invoiceData?.invoiceNumber}</strong>
          </p>

          {(invoiceData.invoiceData?.createdBy && invoiceData.invoiceData?.createdBy.name != null) &&
            <p>
              <strong>Cashier: {invoiceData.invoiceData?.createdBy?.name}</strong>
            </p>
          }
        </div>

        <div className="invoice-primary-details-02">
          <p>
            <strong>{dateTime.format('DD MMM YYYY')}</strong>
          </p>
          <p>
            <strong>{dateTime.format('h.mm a')}</strong>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Header;
