import { environment } from './environment/environment';
import axios, { AxiosHeaders } from 'axios';

export class Util {
  public static apiPublicUrl(path: string): string {
    return environment.api_url + '/api/public/' + path;
  }

  public static apiAuthUrl(path: string): string {
    return environment.api_url + '/api/auth/' + path;
  }

  public static initAxios(): void {
    axios.interceptors.request.use((req) => {
      //   req.headers.authorization = 'Bearer ' + localStorage.getItem('token');
      (req.headers as AxiosHeaders).set(
        'Authorization',
        'Bearer ' + localStorage.getItem('token')
      );

      return req;
    });

    axios.interceptors.response.use(
      function (response) {
        return response.data;
      },
      function (error) {
        return { success: false, data: undefined, error: error };
      }
    );
  }

  public static formatNumber(number: number) {
    const formattedNumber = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(number);

    return formattedNumber;
  }

  public static formatPhoneNumber(formatPhoneNumber: string) {
    if(formatPhoneNumber != null) {
      let numArr = formatPhoneNumber.split("").reverse();

      let newNumber = "";
      
      let index = 0;

      numArr.forEach((element) => {
        if(index == 3 || index == 6) {
          newNumber = " " + element + "" + newNumber;
        } else {
          newNumber = element + "" + newNumber;
        }

        index++;
      });

      return newNumber;
    } else {
      return "";
    }
  }
}

Util.initAxios();
